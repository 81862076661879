import { EmailInput } from "components/inputs/text-input/EmailInput";
import { useCallback, useMemo, useState } from "react";
import { GenericModal } from "../GenericModal";
import { useGatedContent } from "hooks/useGatedContent";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { IFrameType } from "store/VideoSession/types";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import { PurchaseOptions } from "./PurchaseOptions";
import { useGetMetaData } from "hooks/useGetMetaDataByProductId";
import { EntitlementResponse } from "@switcherstudio/player-api-client";
import { AppDispatch } from "store/store";
import { setIsIframeVisible } from "store/VideoSession/slice";
import { useTranslation } from "react-i18next";
import { setActiveModal } from "store/Modals/slice";
import { Modals } from "store/Modals/types";
export interface PurchaseOptionsModalProps {}

export const PurchaseOptionsModal: React.FC<
    PurchaseOptionsModalProps
> = ({}) => {
    const dispatch = useDispatch<AppDispatch>();
    const cx = classNames.bind(styles);
    const { t } = useTranslation();

    const {
        catalog,
        collections,
        hasLoadedCollections,
        hasLoadedCatalog,
        configuredCatalogId,
        configuredCollectionId
    } = useSelector((s: RootState) => s.catalogState);
    const { iframeType, parentFrame, currentCollectionVideo } = useSelector(
        (s: RootState) => s.videoSession
    );
    const { customerEmail, availablePurchaseEntitlements } = useGatedContent();
    const [error, setError] = useState<any>();
    const [email, setEmail] = useState<string>("");

    const { isLoading } = useSelector((s: RootState) => s.loading);

    const productIds = useMemo(
        () =>
            availablePurchaseEntitlements
                ?.map((entitlement) => entitlement?.product?.details.id)
                ?.filter(
                    (productId): productId is string => productId !== undefined
                ) ?? [],
        [availablePurchaseEntitlements]
    );
    const { metadata } = useGetMetaData(productIds);

    const hasLoaded = useMemo(() => {
        if (configuredCollectionId) {
            return hasLoadedCollections;
        }
        if (configuredCatalogId) {
            return hasLoadedCatalog;
        }
        return false;
    }, [
        configuredCatalogId,
        configuredCollectionId,
        hasLoadedCatalog,
        hasLoadedCollections
    ]);

    const sortedEntitlements = useMemo(() => {
        if (!availablePurchaseEntitlements) return [];

        return [...availablePurchaseEntitlements].sort((a, b) => {
            if (a?.details.type === "Catalog") return -1;
            if (b?.details.type === "Catalog") return 1;
            if (a?.details.type === "Collection") return -1;
            if (b?.details.type === "Collection") return 1;
            if (a?.details.type === "Video") return -1;
            if (b?.details.type === "Video") return 1;
            return 0;
        });
    }, [availablePurchaseEntitlements]);

    const handleClose = useCallback(() => {
        dispatch(setActiveModal(Modals.None));
        if (iframeType === IFrameType.AuxModal && parentFrame) {
            dispatch(setIsIframeVisible(false));
            parentFrame.closePurchaseModal();
        }
    }, [iframeType, parentFrame, dispatch]);

    const modalTitle = useMemo(() => {
        // Determine the modal title of the purchase option based on the entitlement types
        const allSubscriptions = availablePurchaseEntitlements?.every(
            (entitlement) =>
                entitlement?.details?.type &&
                ["Catalog", "Collection"].includes(entitlement.details.type) &&
                entitlement?.prices?.[0]?.details?.isRecurring
        );

        return allSubscriptions
            ? t("purchase-options:title-subscribe")
            : t("purchase-options:title-purchase");
    }, [availablePurchaseEntitlements, t]);

    const purchaseOptions = useMemo(() => {
        return sortedEntitlements?.map(
            (entitlement: EntitlementResponse | undefined, index: number) => {
                const isLastItem = index === sortedEntitlements.length - 1;
                let key;
                let entityId;

                const meta = metadata?.items?.find(
                    (item) =>
                        item.entitlementType === entitlement?.details?.type
                );

                // Determine the title of the purchase option based on the entitlement type
                const isRental =
                    entitlement?.prices?.[0]?.details?.isTimeLimitedAccess;
                const isSubscription =
                    entitlement?.details?.type &&
                    ["Catalog", "Collection"].includes(
                        entitlement.details.type
                    ) &&
                    entitlement?.prices?.[0]?.details?.isRecurring;
                let title = `${
                    isRental
                        ? t("purchase-options:rent")
                        : isSubscription
                        ? t("purchase-options:subscribe-to")
                        : t("purchase-options:buy")
                } `; // trailing space is important

                switch (entitlement?.details?.type) {
                    case "Catalog":
                        entityId = catalog?.details?.id;
                        key = catalog?.details?.id;
                        title += `${t("purchase-options:catalog")}`;
                        break;
                    case "Collection":
                        if (collections?.collections?.[0]) {
                            entityId = collections?.collections[0]?.details?.id;
                            key = collections?.collections[0]?.details?.id;
                            title += `${
                                collections.collections[0].details?.name ??
                                t("purchase-options:example-player")
                            }`;
                        }
                        break;
                    case "Video":
                        if (currentCollectionVideo?.broadcast?.details) {
                            entityId =
                                currentCollectionVideo.broadcast.details.id;
                            key = currentCollectionVideo.broadcast.details.id;
                            title += `${
                                currentCollectionVideo.broadcast.details
                                    ?.title ?? t("purchase-options:this-video")
                            }`;
                        }
                        break;
                    default:
                }

                return (
                    <div key={key}>
                        <div className={cx("purchase-option")}>
                            <PurchaseOptions
                                entityId={entityId}
                                entitlement={entitlement}
                                email={email}
                                title={title}
                                metadata={meta}
                                setError={setError}
                            />
                        </div>
                        {!isLastItem && (
                            <div className={cx("separator")}>OR</div>
                        )}
                    </div>
                );
            }
        );
    }, [
        sortedEntitlements,
        cx,
        email,
        catalog?.details?.id,
        metadata?.items,
        t,
        collections.collections,
        currentCollectionVideo?.broadcast?.details
    ]);

    return (
        <div className={cx("purchase-options-modal")}>
            <GenericModal
                title={modalTitle}
                onClose={handleClose}
                preventCloseOnSuccess={false}
                isProcessing={isLoading}
                processingHeader={t("modals:processing")}
                processingDescriptionLines={[
                    t("modals:processing-description")
                ]}
            >
                {hasLoaded && (
                    <div className={cx("purchase-options-container")}>
                        <EmailInput
                            value={email ?? customerEmail ?? ""}
                            onChange={(event) => setEmail(event.target.value)}
                            error={error}
                            isLoading={false}
                        />
                        {purchaseOptions}
                    </div>
                )}
            </GenericModal>
        </div>
    );
};
